import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Typography from '~/components/atoms/Typography'
import ProductCard from '~/components/molecules/ProductCard'

import {
	container, dailymineral__catch, dailymineral__description, dailymineral__title,
	header_text_container,
	pc_hide,
	product_card_list,
	sp_hide
} from './styles'
import { Container } from '~/components/layout/Container'

const DailymineralPage = () => {
	const {
		allMicrocmsDailymineral,
	} = useStaticQuery<GatsbyTypes.dailymineralTopQuery>(graphql`
		query dailymineralTop {
			allMicrocmsDailymineral(sort: {fields: handle, order: ASC}) {
                edges {
                    node {
                        id
                        aroma
                        catch
                        title
                        handle
                        price
                        cardTitle
                        media {
                            image {
                                url
                            }
                        }
                    }
                }
            }
		}
	`)

	return (
		<>
			<Container css={container}>
				<div css={header_text_container}>
					<Typography variant='h5' component='p'>薬用入浴剤</Typography>
					<Typography css={dailymineral__title} component='h1' color='textPrimary' variant='h2'>本物の温泉に近い<br css={pc_hide} />
						<Typography variant='h2' component='span' color='secondary'>とろとろ</Typography>感。
					</Typography>
					<Typography css={dailymineral__catch} variant='h5' component='p'>温泉発、全身トリートメント<br css={pc_hide} />としての“整え”体験。</Typography>
					<Typography css={dailymineral__description} variant='body2' component='p' color='textSecondary'>
							デイリーミネラルは、温泉の力を日常に取り入られるよう開発された、サプリのような入浴剤です。<br
							css={sp_hide} />有効成分のミネラル皮膜が身体を包み、温泉の湯上がり爽快感をお届けします。
					</Typography>
				</div>
			</Container>

			<Container css={container}>
				<ul css={product_card_list}>
					{allMicrocmsDailymineral.edges.map(({ node }) => {
						const isLarge = node.handle.includes('large')
						const isGift = node.handle === 'gift'
						return (
							<li key={node.id}>
								<ProductCard
									handle={node.handle}
									image={node.media[0].image.url}
									description={node.cardTitle}
									price={node.price}
									flavor={isLarge ? `${node.aroma} - Large` : node.aroma}
									link={node.handle}
									isShowTag={!isGift}
								/>
							</li>
						)
					})}
				</ul>
			</Container>

		</>
	)
}

export default DailymineralPage