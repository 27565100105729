import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	border: 1px solid ${palette.grey[500]};
`

export const img_container = css`
	position: relative;
`

export const tag = css`
	position: absolute;
	bottom: 20px;
	right: 20px;
`

export const text_container = css`
	background-color: ${palette.common.white};
	padding: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px;
	}
	
	p {
		margin-top: 10px;
		@media (min-width: ${breakpoints.tablet}px) {
			margin-top: 20px;
		}
	}
`

export const text_description = css`
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 3.2rem;
	}
`

export const text_link = css`
	margin-top: 20px;
	color: ${palette.common.white};
	background-color: ${palette.primary.main};

	:hover {
		background-color: ${palette.primary.light};
	}

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		font-size: 1.6rem;
	}
`