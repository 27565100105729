import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'

export const pc_hide = css`
	@media (min-width: ${breakpoints.pc}px) {
		display: none;
	}
`

export const sp_hide = css`
	@media (orientation: portrait) {
		display: none;
	}
`

export const container = css`
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
`

export const header_text_container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 51px;
		text-align: center;
	}
`

export const dailymineral__title = css`
    margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 19px;
	}
`

export const dailymineral__catch = css`
	margin-top: 20px;
	font-weight: bold;
`

export const dailymineral__description = css`
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 500;
`

export const product_card_list = css`
	display: grid;
	gap: 2rem;

	@media (min-width: ${breakpoints.tablet}px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	
	@media (min-width: ${breakpoints.pc}px) {
		gap: 4rem;
	}
`