import React, { FC } from 'react'
import { Button as MButton } from '@material-ui/core'
import { btn } from '~/components/atoms/Button/styles'

type Props = {
	onClick?: () => void
	disabled?: boolean
}

const Button: FC<Props> = ({ onClick, disabled, children, ...props }) => {
	return (
		<MButton
			color='primary'
			variant='outlined'
			onClick={onClick}
			disabled={disabled}
			css={btn}
			{...props}
		>
			{children}
		</MButton>
	)
}

export default Button
