import React, { FC } from 'react'
import { Link } from 'gatsby'

import Typography from '~/components/atoms/Typography'
import Button from '~/components/atoms/Button'
import Tag from '~/components/atoms/Tag'

import {
	container,
	img_container,
	tag,
	text_container,
	text_link,
	text_description,
} from './styles'

type Props = {
	image?: string
	title?: string
	description?: string
	flavor?: string
	price?: string
	link?: string
	handle?: string
	isShowTag: boolean
}

const ProductCard: FC<Props> = ({ image, title, description, link, price, flavor, handle, isShowTag, ...props }) => (
	<article css={container} {...props}>
		<figure>
			<div css={img_container}>
				{image && (
					<img src={image} alt={title} />
				)}
				{isShowTag && (
					<Tag css={tag}>医薬部外品</Tag>
				)}
			</div>
			<figcaption css={text_container}>
				<Typography variant='subtitle1' component='h3'>
					{flavor ? flavor : ''}
				</Typography>
				<Typography variant='h5' component='p' color='textPrimary' css={text_description}>
					{description ? <span dangerouslySetInnerHTML={{ __html: `${description}` }} /> : ''}
				</Typography>
				{/* FIXME */}
				<Typography variant='subtitle1' component='p' color='textSecondary'>
					{price ? price : ''}{handle && handle !== 'wholesale' && ' 税込'}
				</Typography>
				<Link to={`/products/${link}/`}>
					<Button css={text_link}>詳しく見る</Button>
				</Link>
			</figcaption>
		</figure>
	</article>
)

export default ProductCard