import React from 'react'

import Dailymineral from '~/components/pages/Dailymineral'
import SEO from '~/utils/seo'
import { PageProps } from 'gatsby'

export default function DailymineralPage({ location }: PageProps) {
    return (
        <>
            <SEO
                title='【薬用入浴剤 デイリーミネラル】温泉発、全身トリートメントとしての"整え"体験。 | Onsen*(オンセン)'
                description='本物の温泉に近いとろとろ感。「薬用入浴剤 デイリーミネラル」は、温泉の力を日常に取り入られるよう開発された、サプリのような入浴剤です。有効成分のミネラル皮膜が身体を包み、温泉の湯上がり爽快感をお届けします。'
                image='https://images.microcms-assets.io/assets/4a410f4032b948dd82cfbd2261ae3f6d/f87de56f3c594b0b87d4c9d7376f95ec/20210220.jpg'
                url={location.href}
            >
                <meta
                    property='og:image'
                    content='https://images.microcms-assets.io/assets/4a410f4032b948dd82cfbd2261ae3f6d/872e7c8f94b64558b03540194288a70d/20210224.jpg'
                />
            </SEO>
            <Dailymineral />
        </>
    )
}
